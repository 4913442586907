<template>
    <div class="text-center mt-5">
        <v-progress-circular
        :size="size"
        :color="color"
        indeterminate
        ></v-progress-circular>
        <p>{{ type }} {{ text }}...</p>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'Loading'
        },
        text: {
            type: String,
            default: ''
        },
        size: {
            type: Number | String,
            default: 50
        },
        color: {
            type: String,
            default: 'secondary'
        }
    }
}
</script>