<template>
	<div class="overlay-alert">
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">Overlay Alert</h3>
					<div class="d-flex justify-space-between align-end mb-2">
						<strong class="fs--14 fw--400"> Enable: </strong>
						<v-switch v-model="config.overlayEnabled" color="pinkItem" hide-details></v-switch>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-0">
						This feature displays a customized text message on the live stream when a viewer slaps a
						sticker.
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<section class="pb-4">
					<h3 class="fs--14 fw--400 mt-3">Message:</h3>
					<v-textarea
						v-model="config.overlayText"
						@keydown="$emit('onRangeDataStart')"
						@focusout="$emit('onRangeDataEnd')"
						class="mt-2"
						flat
						background-color="purple"
						color="textColor"
						rows="2"
						no-resize
						solo
						hide-details></v-textarea>

					<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
						Customize the alert message to be posted in the chat room. You can use the following tags:
						<span class="pink--text">#user</span> = viewer name, <span class="pink--text">#sticker</span> =
						sticker name, <span class="pink--text">#bits</span> = amount of Bits used
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<section class="pb-4">
					<h3 class="fs--16 fw--400 mt-3">Options:</h3>
					<v-row no-gutters align-sm="end" class="mt-4 mb-0">
						<v-col cols="12" sm="6" class="pr-sm-3 d-flex align-center">
							<strong class="fs--14 fw--400"> Font Color: </strong>

							<v-menu offset-y :close-on-content-click="false" class="bits-btn">
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on" class="color-box ml-8 ml-sm-4" id="font-color"></div>
								</template>
								<div class="purple">
									<v-color-picker
										v-model="overlayColorData.overlayColor"
										@input="actions.setFontColor"
										dot-size="15"
										canvas-height="50px"
										width="250px">
									</v-color-picker>
								</div>
							</v-menu>
							<!-- <div class="color-box ml-8 ml-sm-4"></div> -->
						</v-col>
						<v-col cols="12" sm="6" class="mt-2 mt-sm-0 d-flex align-center justify-sm-end">
							<strong class="fs--14 fw--400"> Outline Color: </strong>

							<v-menu offset-y :close-on-content-click="false" class="bits-btn">
								<template v-slot:activator="{ on, attrs }">
									<div
										v-bind="attrs"
										v-on="on"
										id="outline-color"
										class="color-box ml-8 ml-sm-4"></div>
								</template>
								<div class="purple">
									<v-color-picker
										v-model="overlayColorData.overlayOutline"
										@input="actions.setFontOutline"
										dot-size="15"
										canvas-height="50px"
										width="250px">
									</v-color-picker>
								</div>
							</v-menu>
							<!-- <div class="color-box"></div> -->
						</v-col>
					</v-row>

					<v-row no-gutters align-sm="end" class="mt-3 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3 mb-3" align-self="center">
							<strong class="fs--14 fw--400"> Font Family: </strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" align-self="center" class="mt-2 mt-sm-0 mb-3">
							<v-autocomplete
								v-model="config.overlayFontFamily"
								:items="fontList"
								item-text="family"
								item-value="family"
								background-color="purple"
								flat
								solo
								dense
								hide-details
								@change="actions.setFontFamily">
								<template v-slot:selection="{ item }">
									<span class="fs--14 fw--500" id="selectedOverlayFont">{{ item.family }}</span>
								</template>
								<template v-slot:item="{ item }">
									<span class="fs--14 fw--500" :style="{ fontFamily: item.family }">{{
										item.family
									}}</span>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400"> Font Size: </strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="config.overlayFontSize"
								@change="actions.setFontSize"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="10"
								:max="59"
								thumb-color="pink"
								track-fill-color="pink"
								track-color="purpleDivider"
								hide-details>
								<template #prepend>
									<div class="d-flex mt-1">
										{{ config.overlayFontSize }}
										<span class="pl-1">pt</span>
									</div>
								</template>
							</v-slider>
						</v-col>
					</v-row>

					<div class="d-flex align-end justify-space-between">
						<strong class="fs--14 fw--400"> Disable Stroke: </strong>
						<v-switch
							color="pink"
							v-model="config.hideOverlayStroke"
							@change="actions.toggleStroke"
							hide-details></v-switch>
					</div>

					<div class="d-flex align-end justify-space-between">
						<strong class="fs--14 fw--400"> Top of screen: </strong>
						<v-switch color="pink" v-model="config.overlayOnTop" hide-details></v-switch>
					</div>

					<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
						Click the colored swatch to open the color picker.
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<section class="pb-4">
					<h3 class="fs--16 fw--400 mt-3">Preview:</h3>

					<div class="preview-box purple mt-3">
						<strong id="preview-text" class="overlay-alert-text">John slapped LOL for 100 BITS</strong>
					</div>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { onMounted, watch, computed } from '@vue/composition-api';
import { mdiAlertCircleOutline } from '@mdi/js';
import store from '@/store';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
		overlayColorData: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup(props) {
		const fontList = computed(() => store.state.app.googleFonts);

		onMounted(() => {
			if (props.config) {
				actions.initSettings();
			}
		});

		// This watch is mainly to set the initial color and font values.
		// When we fix this "Settings save on load issue", we may need
		// a different approach here.
		watch(props.overlayColorData, () => {
			actions.initSettings();
		});

		// Calculate the text shadow if the stroke is enabled.
		const calcTextShadow = (ow, oc) => {
			return (
				'-' +
				ow +
				' -' +
				ow +
				' 0 ' +
				oc +
				', ' +
				' ' +
				ow +
				' -' +
				ow +
				' 0 ' +
				oc +
				', ' +
				'-' +
				ow +
				'  ' +
				ow +
				' 0 ' +
				oc +
				', ' +
				' ' +
				ow +
				'  ' +
				ow +
				' 0 ' +
				oc
			);
		};

		const actions = {
			initSettings() {
				if (props.config.overlayFontFamily) {
					actions.setFontFamily(props.config.overlayFontFamily);
				}

				actions.setFontSize(props.config.overlayFontSize);
				actions.setFontColor(props.overlayColorData.overlayColor);
				actions.setFontOutline(props.overlayColorData.overlayOutline);
				actions.toggleStroke(props.config.hideOverlayStroke);
				actions.loadFonts();
			},

			loadFonts() {
				if (!fontList.value || fontList.value.length < 1) {
					store.dispatch('app/loadGoogleFonts').catch(err => {
						console.log(err);
					});
				}
			},

			setFontFamily: font => {
				if (font) {
					const family = typeof font === 'object' ? font.family : font;

					const fontLabel = document.getElementById('selectedOverlayFont');
					if (fontLabel) {
						fontLabel.style.fontFamily = `${family}`;
					} else {
						console.warn('font label not found.');
					}

					const previewText = document.getElementById('preview-text');
					if (previewText) {
						previewText.style.fontFamily = `${family}`;
					}
				}
			},
			setFontSize: value => {
				const text = document.getElementById('preview-text');
				text.style.fontSize = Number(value) + 'pt';
			},

			setFontColor: value => {
				const text = document.getElementById('preview-text');
				text.style.color = value;

				const bg = document.getElementById('font-color');
				bg.style.background = value;
			},

			setFontOutline: value => {
				if (props.config.hideOverlayStroke) {
					const text = document.getElementById('preview-text');
					text.style['-webkit-text-stroke-color'] = value;

					const bg = document.getElementById('outline-color');
					bg.style.background = value;
				}
			},

			toggleStroke: value => {
				const text = document.getElementById('preview-text');
				text.style.textShadow = calcTextShadow('1px', value);

				if (value) {
					text.style['-webkit-text-stroke-width'] = '0px';
				} else {
					text.style['-webkit-text-stroke-width'] = '1px';
				}
			},

			toggleStroke: value => {
				const text = document.getElementById('preview-text');

				if (value) {
					text.style.textShadow = '';
				} else {
					text.style.textShadow = calcTextShadow('1px', props.overlayColorData.overlayOutline);
				}
			},
		};

		const icons = { mdiAlertCircleOutline };

		return {
			fontList,
			actions,
			icons,
		};
	},
};
</script>

<style lang="scss" scoped>
.overlay-alert {
	.color-box {
		width: 80px;
		height: 25px;
		border-radius: 4px;
		background: white;
		margin-left: 15px;
	}

	.preview-box {
		min-height: 80px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px;

		strong {
			text-align: center;
			font-size: 18pt;
			font-family: 'Gotham Rounded', sans-serif;
			font-weight: bold !important;
		}
	}
}
</style>
