<template>
	<div class="general">
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400 mb-2">General:</h3>
					<v-row no-gutters align-sm="center" class="mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Master Volume:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="config.masterVolume"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="0"
								:max="100"
								thumb-color="pink"
								thumb-label
								track-fill-color="pink"
								track-color="purpleDivider"
								hide-details>
								<template #thumb-label>
									<span class="px-2">{{ config.masterVolume }}%</span>
								</template>
							</v-slider>
						</v-col>
					</v-row>
					<p class="fieldFore--text fs--12 fw--400">
						This is the master volume of all of your stickers. This setting will blend together with your
						individual sticker volume.
					</p>

					<v-row no-gutters align-sm="center" class="mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Sticker Cooldown:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="cooldownTime"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								min="0"
								max="10"
								thumb-color="pink"
								thumb-label
								track-fill-color="pink"
								track-color="purpleDivider"
								hide-details></v-slider>
						</v-col>
					</v-row>

					<p class="fieldFore--text fs--12 fw--400">
						The waiting time until the same viewer can place another sticker (range is between 0 and 10
						seconds).
					</p>

					<v-row no-gutters align-sm="center" class="mb-0">
						<v-col cols="12" sm="5" md="3" lg="4" class="pr-sm-3 pr-md-2 pr-lg-3">
							<strong class="fs--14 fw--400">Sticker Size:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="9" lg="8" class="mt-2 mt-sm-0 text-right">
							<v-item-group v-model="config.globalStickerSize" active-class="secondary" mandatory>
								<v-item v-slot="{ active, toggle }" style="display: none">
									<v-btn
										small
										:class="!active ? 'secondary-outline' : 'secondary'"
										:outlined="!active"
										depressed
										@click="toggle">
										<span>Small</span>
									</v-btn>
								</v-item>
								<v-item v-slot="{ active, toggle }">
									<v-btn
										small
										class="mx-1 mx-sm-2"
										:class="!active ? 'secondary-outline' : 'secondary'"
										:outlined="!active"
										depressed
										@click="toggle">
										<span>Regular</span>
									</v-btn>
								</v-item>
								<v-item v-slot="{ active, toggle }">
									<v-btn
										small
										:outlined="!active"
										:class="!active ? 'secondary-outline' : 'secondary'"
										depressed
										@click="toggle">
										<span>Large</span>
									</v-btn>
								</v-item>
							</v-item-group>
						</v-col>
					</v-row>

					<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
						Controls how large the global stickers will appear on your stream window.
					</p>
				</section>

				<!-- <v-divider class="purpleDivider"></v-divider> -->
			</v-card-text>
		</v-card>
		<v-card class="mt-5">
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">Default Settings for New Stickers:</h3>
					<v-row no-gutters align-sm="center" class="mt-2 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Stickiness:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="newGlobalStickerStickiness"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="10"
								:max="45"
								thumb-color="pink"
								thumb-label
								track-fill-color="pink"
								track-color="purpleDivider"
								hide-details></v-slider>
						</v-col>
					</v-row>
					<v-row no-gutters align-sm="center" class="mt-2 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Volume:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="config.newGlobalStickerVolume"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="0"
								:max="100"
								thumb-color="pink"
								thumb-label
								track-fill-color="pink"
								track-color="purpleDivider"
								hide-details>
								<template #thumb-label>
									<span class="px-2">{{ config.newGlobalStickerVolume }}%</span>
								</template>
							</v-slider>
						</v-col>
					</v-row>
					<v-row no-gutters align-sm="center" class="mt-2">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Bits:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0 text-right pr-2">
							<BitsDropdown
								:sku="config.newGlobalStickerSku"
								@onChange="actions.setGlobalStickerSku"></BitsDropdown>
						</v-col>
					</v-row>

					<h3 class="fs--22 fw--400 mt-5">Default Settings for New TTS Stickers:</h3>
					<v-row no-gutters align-sm="center" class="mt-2 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Stickiness:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="newTtsStickerStickiness"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="10"
								:max="45"
								thumb-color="pink"
								thumb-label
								track-fill-color="pink"
								track-color="purpleDivider"
								hide-details></v-slider>
						</v-col>
					</v-row>
					<v-row no-gutters align-sm="center" class="mt-2 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Volume:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="config.newTtsStickerVolume"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="0"
								:max="100"
								thumb-color="pink"
								thumb-label
								track-fill-color="pink"
								track-color="purpleDivider"
								hide-details>
								<template #thumb-label>
									<span class="px-2">{{ config.newTtsStickerVolume }}%</span>
								</template>
							</v-slider>
						</v-col>
					</v-row>
					<v-row no-gutters align-sm="center" class="mt-2">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Bits:</strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0 text-right pr-2">
							<BitsDropdown
								:sku="config.newTtsStickerSku"
								:isGlobalTts="true"
								@onChange="actions.setTtsStickerSku"></BitsDropdown>
						</v-col>
					</v-row>

					<v-row no-gutters align-sm="center" class="my-2">
						<v-col cols="9">
							<strong class="fs--14 fw--400">Auto-Enable Custom Placement for My Sticker Uploads:</strong>
						</v-col>
						<v-col cols="3" class="d-flex justify-end">
							<v-switch
								v-model="config.isPlacementCustomEnabled"
								color="pinkItem"
								class="ma-0"
								hide-details></v-switch>
						</v-col>
					</v-row>
					<p class="fieldFore--text fs--12 fw--400 mb-0">
						This setting will auto-enable the Custom Placement toggle when you upload stickers in the
						Dashboard for any sticker type. This can be adjusted after upload within each individual
						sticker's configuration settings.
					</p>
					<v-row no-gutters align-sm="center" class="my-2">
						<v-col cols="9">
							<strong class="fs--14 fw--400">
								Auto-Enable Custom Placement for New Global Stickers:
							</strong>
						</v-col>
						<v-col cols="3" class="d-flex justify-end">
							<v-switch
								v-model="config.isPlacementGlobalEnabled"
								color="pinkItem"
								class="ma-0"
								hide-details></v-switch>
						</v-col>
					</v-row>
					<p class="fieldFore--text fs--12 fw--400 mb-0">
						This setting will auto-enable Custom Placement for "Placement Optimized" Global Stickers that we
						add to the extension.
						<strong>For better performance, we highly recommend keeping this option enabled.</strong>
						You will still have the ability to manually disable the custom placement options for individual
						stickers in the dashboard at any time.
					</p>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { getEndpointHost } from '../../../utils/config';
import { getUserId } from '../../../utils/auth';
import { rangeValueConverter } from '../../../utils/sticker.js';
import BitsDropdown from '@/views/shared/BitsDropdown.vue';
import { mdiChevronDown } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	components: {
		BitsDropdown,
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup(props) {
		// Calculated UI value for cooldown.
		const cooldownTime = computed({
			get() {
				return rangeValueConverter.toUiValue(props.config.cooldownTime, 0, 10, 100);
			},
			set(newValue) {
				props.config.cooldownTime = rangeValueConverter.toDbValue(newValue, 0, 10, 100);
			},
		});

		// Calculated UI value for stickiness.
		const newGlobalStickerStickiness = computed({
			get() {
				return rangeValueConverter.toUiValue(props.config.newGlobalStickerStickiness, 10, 45, 100);
			},
			set(newValue) {
				props.config.newGlobalStickerStickiness = rangeValueConverter.toDbValue(newValue, 10, 45, 100);
			},
		});

		const newTtsStickerStickiness = computed({
			get() {
				return rangeValueConverter.toUiValue(props.config.newTtsStickerStickiness, 10, 45, 100);
			},
			set(newValue) {
				props.config.newTtsStickerStickiness = rangeValueConverter.toDbValue(newValue, 10, 45, 100);
			},
		});

		const actions = {
			// Called after the # of bits is selected.
			setGlobalStickerSku: function (item) {
				props.config.newGlobalStickerSku = item.value;
			},
			setTtsStickerSku: function (item) {
				props.config.newTtsStickerSku = item.value;
			},
			// Copy the OBS URL to the clipboard.
			copy: () => {
				navigator.clipboard.writeText(browserSourceLink).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
		};

		const browserSourceLink = `https://${getEndpointHost()}:8081/stickers/${getUserId()}/mt`;
		const copied = ref(false);

		return {
			actions,
			cooldownTime,
			newGlobalStickerStickiness,
			newTtsStickerStickiness,
			browserSourceLink,
			copied,
			icons: {
				arrow: mdiChevronDown,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.secondary-outline {
	border-color: var(--v-secondary-base) !important;
	background-color: transparent !important;
}
</style>
