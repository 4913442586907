<template>
	<div class="chat-alert">
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">Blocked Terms and Phrases</h3>
					<p class="fieldFore--text fs--12 fw--400 mt-3">
						Add words and phrases that should be blocked for TTS Stickers.
					</p>

					<v-row no-gutters>
						<v-col cols="12" sm="9" md="8" lg="9" xl="10">
							<v-text-field v-model="search" placeholder="Search Terms and Phrases" solo flat dense
								background-color="purple" hide-details :append-icon="icons.searchIcon"></v-text-field>
						</v-col>
						<v-col cols="12" sm="3" md="4" lg="3" xl="2" class="text-right pl-sm-1">
							<v-btn color="pink" depressed @click="actions.openModal('add')">Add New</v-btn>
						</v-col>
					</v-row>

					<v-data-table class="activity-feed-table" :headers="headers" :search="search" :items="rows"
						:items-per-page="5" :hide-default-footer="rows.length < 1">
						<template v-slot:[`item.value`]="{ item, index }">
							<div class="d-flex align-center justify-space-between">
								<span v-if="item.visibility">{{ item.value }}</span>
								<span v-else>{{ actions.hideMiddleCharacters(item.value) }}</span>
								<v-icon @click="actions.toggleVisibility(item, index)" size="18" color="red">{{
									item.visibility ? icons.eyeIcon : icons.eyeOff
								}}</v-icon>
							</div>
						</template>
						<template v-slot:[`item.action`]="{ item }">
							<v-icon color="info" @click="actions.openModal('edit', item)">{{ icons.editIcon }}</v-icon>
							<v-icon color="red" class="ml-2" @click="actions.openModal('delete', item)">{{
								icons.deleteIcon
							}}</v-icon>
						</template>
					</v-data-table>
				</section>
			</v-card-text>
		</v-card>

		<v-dialog v-model="modal" max-width="500">
			<v-card>
				<div class="upload-header">
					<h3 class="text-capitalize text-center d-none d-sm-block mt-1 fs--22 fw--500">
						{{ eventType }} Term or Phrase
					</h3>

					<h3 class="text-capitalize text-center mt-1 d-block d-sm-none fs--18 fw--500">
						{{ eventType }} Term or Phrase
					</h3>

					<v-icon class="close-icon" @click="modal = false">{{ icons.closeIcon }}</v-icon>
				</div>
				<v-card-text class="pt-5">
					<v-form name="block-form" ref="blockWordForm" @submit.prevent="actions.saveTerm" lazy-validation>
						<template v-if="eventType === 'add'">
							<v-text-field v-model="selectedItem.value" placeholder="Enter Term or Phrase" solo flat dense
								:rules="[v => !!v || 'Please enter any word']" background-color="purple"></v-text-field>

							<div class="text-right mt-3">
								<v-btn type="submit" :loading="isLoading" color="pink" depressed>Save</v-btn>
							</div>
						</template>
						<template v-if="eventType === 'edit' && selectedItem">
							<v-text-field v-model="selectedItem.value" placeholder="Enter Term or Phrase" solo flat dense
								:rules="[v => !!v || 'Please enter any word']" background-color="purple"></v-text-field>

							<div class="text-right mt-5">
								<v-btn type="submit" :loading="isLoading" color="pink" depressed>Save</v-btn>
							</div>
						</template>
					</v-form>
					<template v-if="eventType === 'delete' && selectedItem">
						<div class="text-center">
							<span class="fs--16 fw--500 fieldFore--text">Are you sure you want to delete "{{
								selectedItem.value }}" ?</span>

							<div class="mt-5 text-center">
								<v-btn @click="actions.deleteTerm" :loading="isLoading" color="red" depressed>Yes,
									Delete</v-btn>
								<v-btn color="secondary" class="ml-2" :disabled="isLoading" @click="modal = false"
									depressed>No, Cancel</v-btn>
							</div>
						</div>
					</template>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import axios from '@axios';
import { mdiMagnify, mdiPencil, mdiDelete, mdiClose, mdiEye, mdiEyeOff } from '@mdi/js';
export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup() {
		const isLoading = ref(false);
		const selected = ref([]);
		const search = ref(null);
		const modal = ref(false);
		const eventType = ref('');
		const word = ref('');
		const selectedItem = ref();
		const blockWordForm = ref(null);
		const headers = [
			{
				text: 'Terms and Pharses',
				value: 'value',
			},
			{
				text: 'Privacy',
				value: 'privacy',
			},
			{
				text: 'Action',
				value: 'action',
			},
		];

		const rows = reactive([]);

		const actions = {
			hideMiddleCharacters(value) {
				if (value && value.length > 2) {
					const words = [...value];
					const first = words[0];
					const last = words[words.length - 1];

					const list = [];
					for (let char = 1; char < words.length - 1; char++) {
						if (words[char] !== ' ') {
							list.push('*');
						} else {
							list.push(' ');
						}
					}

					return first + list.join('') + last;
				} else if (value && value.length === 2) {
					const last = value.charAt(value.length - 1);
					return '*' + last;
				}

				return value || '';
			},
			toggleVisibility(item, index) {
				rows[index].visibility = !item.visibility;
			},
			openModal(event, item) {
				eventType.value = event;

				if (item) {
					selectedItem.value = { ...item };
				} else {
					selectedItem.value = {};
				}

				modal.value = true;
			},
			fetchData() {
				axios.get('/tts-blocked-content').then(res => {
					rows.length = 0;
					res.data.forEach(item => {
						const data = {
							...item,
							visibility: false,
						};

						rows.push(data);
					});
				});
			},
			saveTerm() {
				if (blockWordForm.value.validate()) {
					isLoading.value = true;
					if (selectedItem.value.id) {
						axios
							.put('/tts-blocked-content', selectedItem.value)
							.then(res => {
								rows.length = 0;
								res.data.for;
								rows.push(...res.data);
								modal.value = false;
							})
							.finally(() => {
								isLoading.value = false;
							});
					} else {
						axios
							.post('/tts-blocked-content', selectedItem.value)
							.then(res => {
								rows.length = 0;
								rows.push(...res.data);
								modal.value = false;
							})
							.finally(() => {
								isLoading.value = false;
							});
					}
				}
			},
			deleteTerm() {
				if (selectedItem.value.id) {
					isLoading.value = true;
					axios
						.delete('/tts-blocked-content/' + selectedItem.value.id)
						.then(res => {
							rows.length = 0;
							rows.push(...res.data);
							modal.value = false;
						})
						.finally(() => {
							isLoading.value = false;
						});
				}
			},
		};

		// Load data.
		actions.fetchData();

		return {
			icons: {
				searchIcon: mdiMagnify,
				editIcon: mdiPencil,
				deleteIcon: mdiDelete,
				closeIcon: mdiClose,
				eyeIcon: mdiEye,
				eyeOff: mdiEyeOff,
			},
			blockWordForm,
			isLoading,
			modal,
			eventType,
			word,
			selectedItem,
			actions,
			search,
			headers,
			rows,
			selected,
			items: ['test', 'word', 'block', 'tts', 'stickers', 'demo', 'dumy', 'stupid'],
		};
	},
};
</script>

<style scoped lang="scss">
.upload-header {
	position: relative;
	padding: 12px 0px;
	background: url('../../../assets/images/backgrounds/sticker-upload-header.png');
	background-repeat: no-repeat;
	background-size: fill;

	h3 {
		margin-top: -15px;
	}
}

.close-icon {
	position: absolute;
	top: 18px;
	right: 15px;
}
</style>
