<template>
	<div class="tts-chat-bubble">
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">TTS Text Bubble</h3>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						Display an animated text bubble on screen when a TTS Message is read. This message will display
						within your main browser source for all TTS Sticker Types.
					</p>

					<div class="d-flex align-end justify-space-between">
						<strong class="fs--14 fw--400">Enabled: </strong>
						<v-switch color="pink" v-model="data.isEnabled" hide-details></v-switch>
					</div>

					<h3 class="fs--16 fw--400 mt-3">Color Theme Preview:</h3>

					<section v-if="data.isEnabled" class="pb-4" id="chat-section">
						<div id="chatPreview">
							<div class="preview-box container fade-in-up mt-3" id="previewBox">
								<div class="user-profile" id="user-profile">
									<div class="profile-box" id="profile-box">
										<div class="user-avatar" id="user-avatar"></div>
										<div class="username fs--14 fw-400" id="username">
											<strong>Sender Name </strong>
											<template v-if="data.isBitsShowEnabled">
												<span>sent a TTS message for</span> <strong>500</strong> bits!
											</template>
										</div>
									</div>
								</div>
								<div class="bubble d-flex flex-column align-center justify-center" id="bubble">
									<div class="chat-bubble" id="chat-bubble">
										<span class="" id="preview-bubble-text">{{ dummyText }}</span>
									</div>
								</div>
							</div>
						</div>
					</section>

					<v-row align-sm="end" class="mt-4 mb-0">
						<v-col cols="12" sm="6" xl="4" class="pr-sm-3 d-flex align-center">
							<strong class="fs--14 fw--400">Primary: </strong>

							<v-menu
								:disabled="!data.isEnabled"
								offset-y
								:close-on-content-click="false"
								class="bits-btn">
								<template v-slot:activator="{ on, attrs }">
									<div
										v-bind="attrs"
										v-on="on"
										id="primary-color"
										class="color-box ml-8 ml-sm-4"></div>
								</template>
								<div class="purple">
									<v-color-picker
										v-model="data.primaryColor"
										@input="actions.setPrimaryColor"
										:disabled="!data.isEnabled"
										dot-size="15"
										canvas-height="50px"
										width="250px">
									</v-color-picker>
								</div>
							</v-menu>
						</v-col>

						<v-col cols="12" sm="6" lg="4" class="mt-2 mt-sm-0 d-flex align-center justify-sm-end">
							<strong class="fs--14 fw--400">Secondary:</strong>

							<v-menu
								:disabled="!data.isEnabled"
								offset-y
								:close-on-content-click="false"
								class="bits-btn">
								<template v-slot:activator="{ on, attrs }">
									<div
										v-bind="attrs"
										v-on="on"
										id="secondary-color"
										class="color-box ml-8 ml-sm-4"></div>
								</template>
								<div class="purple">
									<v-color-picker
										v-model="data.secondaryColor"
										@input="actions.setSecondaryColor"
										:disabled="!data.isEnabled"
										dot-size="15"
										canvas-height="50px"
										width="250px">
									</v-color-picker>
								</div>
							</v-menu>
						</v-col>

						<v-col cols="12" sm="6" lg="4" class="pr-sm-3 d-flex align-center">
							<strong class="fs--14 fw--400">Text: </strong>

							<v-menu
								:disabled="!data.isEnabled"
								offset-y
								:close-on-content-click="false"
								class="bits-btn">
								<template v-slot:activator="{ on, attrs }">
									<div
										v-bind="attrs"
										v-on="on"
										class="color-box ml-8 ml-sm-4 ml-md-10"
										id="font-color"></div>
								</template>
								<div class="purple">
									<v-color-picker
										v-model="data.textColor"
										@input="actions.setFontColor"
										dot-size="15"
										:disabled="!data.isEnabled"
										canvas-height="50px"
										width="250px">
									</v-color-picker>
								</div>
							</v-menu>
							<!-- <div class="color-box ml-8 ml-sm-4"></div> -->
						</v-col>
					</v-row>

					<v-row no-gutters align="end" class="mt-3 mt-md-0 mb-0">
						<v-col cols="4" sm="5" md="5" lg="4" class="pr-sm-3 mb-3" align-self="center">
							<strong class="fs--14 fw--400"> Font Family: </strong>
						</v-col>
						<v-col cols="8" sm="7" md="7" lg="8" align-self="center" class="mt-2 mt-sm-0 mb-3">
							<v-autocomplete
								v-model="data.fontFamily"
								:items="fontList"
								item-text="family"
								item-value="family"
								background-color="purple"
								flat
								solo
								dense
								hide-details
								@change="actions.setFontFamily">
								<template v-slot:selection="{ item }">
									<span class="fs--14 fw--500" id="selectedBubbleFont">{{ item.family }}</span>
								</template>
								<template v-slot:item="{ item }">
									<span class="fs--14 fw--500" :style="{ fontFamily: item.family }">{{
										item.family
									}}</span>
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>

					<v-divider class="purpleDivider"></v-divider>

					<div class="d-flex flex-wrap mt-3">
						<h3 class="fs--16 fw--400 mr-3">Additional Configurations:</h3>
						<span class="fieldFore--text fs--12 fw--400 mb-0">
							The following configurations will be applied in your browser source only.
						</span>
					</div>

					<v-row no-gutters align-sm="end" class="mt-3 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400"> Font Size: </strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="data.fontSize"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="20"
								:max="78"
								thumb-label
								thumb-color="pink"
								track-fill-color="pink"
								track-color="purpleDivider"
								:disabled="!data.isEnabled"
								hide-details>
								<template #thumb-label>
									<div class="d-flex mt-1">
										<span>{{ data.fontSize }}px</span>
									</div>
								</template>
							</v-slider>
						</v-col>
					</v-row>

					<v-row no-gutters align-sm="end" class="mt-3 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Bubble Width: </strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="data.bubbleWidth"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="25"
								:max="100"
								thumb-label
								thumb-color="pink"
								track-fill-color="pink"
								track-color="purpleDivider"
								:disabled="!data.isEnabled"
								hide-details>
								<template #thumb-label>
									<div class="d-flex mt-1">
										<span class="">{{ data.bubbleWidth }}%</span>
									</div>
								</template>
							</v-slider>
						</v-col>
					</v-row>

					<v-row no-gutters align-sm="end" class="mt-3 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">X Offset: </strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="data.xOffset"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="-100"
								:max="100"
								thumb-label
								thumb-color="pink"
								track-fill-color="pink"
								track-color="purpleDivider"
								:disabled="!data.isEnabled"
								hide-details>
								<template #thumb-label>
									<div class="d-flex mt-1">
										<span>{{ data.xOffset }}%</span>
									</div>
								</template>
							</v-slider>
						</v-col>
					</v-row>

					<v-row no-gutters align-sm="end" class="mt-3 mb-0">
						<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
							<strong class="fs--14 fw--400">Y Offset: </strong>
						</v-col>
						<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
							<v-slider
								v-model="data.yOffset"
								@start="$emit('onRangeDataStart')"
								@end="$emit('onRangeDataEnd')"
								:min="0"
								:max="100"
								thumb-label
								thumb-color="pink"
								track-fill-color="pink"
								track-color="purpleDivider"
								:disabled="!data.isEnabled"
								hide-details>
								<template #thumb-label>
									<div class="d-flex mt-1">
										<span>{{ data.yOffset }}%</span>
									</div>
								</template>
							</v-slider>
						</v-col>
					</v-row>

					<div class="d-flex align-end justify-space-between">
						<strong class="fs--14 fw--400">Show at the top of the screen: </strong>
						<v-switch color="pink" :disabled="!data.isEnabled" v-model="data.isTop" hide-details></v-switch>
					</div>

					<div class="d-flex align-end justify-space-between">
						<strong class="fs--14 fw--400">Show Bits Used: </strong>
						<v-switch
							color="pink"
							:disabled="!data.isEnabled"
							v-model="data.isBitsShowEnabled"
							hide-details></v-switch>
					</div>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<section class="pb-4">
					<div class="d-flex align-center mt-4 mb-3">
						<h3 class="fs--16 fw--400">Preview in Browser Source:</h3>
						<v-btn
							color="pink"
							class="ml-2"
							:disabled="!isExtensionOnline || !data.isEnabled || isPreview"
							depressed
							@click="actions.previewInBrowserSource"
							>Preview</v-btn
						>
					</div>

					<p class="fieldFore--text fs--12 fw--400 mb-0">
						Note: To maintain visibility, please ensure your main browser source remains at the top of your
						scene in OBS.
					</p>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { computed, ref, watch, onMounted, onBeforeUnmount } from '@vue/composition-api';
import axios from '@axios';
import { getExtensionStatus } from '@/utils/sticker';
import { MessageEnum } from '@/utils/constants';
import { mdiAlertCircleOutline } from '@mdi/js';
import store from '@/store';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup(props) {
		const dummyText = ref('This is a test message to preview your color theme settings.');
		const isPreview = ref(false);

		const data = computed(() => {
			return props.config.ttsAttrs.textBubble;
		});

		const isExtensionOnline = ref(false);
		getExtensionStatus(data => {
			isExtensionOnline.value = data.online;
		});

		// Handle WS events.
		const eventBus = require('js-event-bus')();
		eventBus.on('ws-message', function (payload) {
			if (payload.method == MessageEnum.EXTENSION_STATUS) {
				isExtensionOnline.value = payload.value;
			}
		});

		const fontList = computed(() => store.state.app.googleFonts);

		// Stop event listener.
		onBeforeUnmount(() => {
			eventBus.detachAll();
		});

		onMounted(() => {
			actions.initBubble();
			actions.loadFonts();

			if (!data.value.fontFamily) {
				data.value.fontFamily = 'Inter';
			}
			actions.setFontFamily(data.value.fontFamily);
		});
		// This watch is mainly to set the initial color and font values.
		// When we fix this "Settings save on load issue", we may need
		// a different approach here.
		watch(props.config.ttsAttrs.textBubble, () => {
			setTimeout(() => {
				actions.initBubble();
			}, 100);
		});
		const actions = {
			initBubble: () => {
				actions.setPrimaryColor(data.value.primaryColor);
				actions.setSecondaryColor(data.value.secondaryColor);
				actions.setFontColor(data.value.textColor);

				// actions.setFontSize(data.value.fontSize);
				// actions.setBubbleWidth(data.value.bubbleWidth);
				// actions.calculateContainerHeight()
			},

			loadFonts() {
				if (!fontList.value || fontList.value.length < 1) {
					store.dispatch('app/loadGoogleFonts').catch(err => {
						console.log(err);
					});
				}
			},

			setFontFamily: font => {
				if (font) {
					const family = typeof font === 'object' ? font.family : font;

					const fontLabel = document.getElementById('selectedBubbleFont');
					const previewText = document.getElementById('preview-bubble-text');
					const username = document.getElementById('username');

					if (fontLabel) {
						fontLabel.style.fontFamily = `${family}`;
					}

					previewText.style.fontFamily = `${family}`;
					username.style.fontFamily = `${family}`;

					// store.dispatch('app/loadSingleFont', family).then(() => {
					// 	const fontLabel = document.getElementById('selectedBubbleFont')
					// 	fontLabel.style.fontFamily = `${family}`;
					// })
				}
			},
			// setFontSize: value => {
			// 	const text = document.getElementById('preview-bubble-text');
			// 	text.style.fontSize = Number(value) + 'px';
			// },

			setPrimaryColor: value => {
				const color = value && typeof value === 'object' && value.hex ? value.hex : value;

				const bg = document.getElementById('primary-color');
				const chatBubble = document.getElementById('chat-bubble');
				const bubble = document.getElementById('bubble');
				const profileBox = document.getElementById('profile-box');

				if (bubble && chatBubble) {
					bg.style.background = color;

					bubble.style.setProperty('--bubble-triangle-color', color);

					chatBubble.style.background = color;

					profileBox.style.background = color;

					const avatar = document.getElementById('user-avatar');
					avatar.style.borderColor = color;
				}
			},

			setSecondaryColor: value => {
				const color = value && typeof value === 'object' && value.hex ? value.hex : value;

				const bg = document.getElementById('secondary-color');
				const bubble = document.getElementById('bubble');

				if (bubble) {
					bg.style.background = color;

					bubble.style.filter = `drop-shadow(4.5px 5.468px 0px ${color})`;

					const profileBox = document.getElementById('profile-box');
					profileBox.style.boxShadow = `4.5px 5.468px 0px ${color}`;
				}
			},

			setFontColor: value => {
				const color = value && typeof value === 'object' && value.hex ? value.hex : value;

				const text = document.getElementById('preview-bubble-text');
				const bg = document.getElementById('font-color');
				const bubble = document.getElementById('chat-bubble');
				const username = document.getElementById('username');

				if (bubble) {
					text.style.color = color;

					bg.style.background = color;

					bubble.style.setProperty('--blinking-border-color', color);

					text.style.borderRightWidth = '0.15em';
					text.style.borderRightColor = color;
					username.style.color = color;
				}
			},

			setBubbleWidth: value => {
				const userProfile = document.getElementById('user-profile');
				userProfile.style.width = `${value}%`;

				// const bubble = document.getElementById('bubble');
				// bubble.style.width = `${value}%`;

				// actions.calculateContainerHeight();
			},

			setBubbleXOffset: value => {
				const previewBox = document.getElementById('previewBox');

				const space = (100 - data.value.bubbleWidth) / 2;

				if (value < 0) {
					const xOffset = (value / 100) * space;
					previewBox.style.left = `${xOffset}%`;
				}
				if (value > 0) {
					const xOffset = (value / 100) * space;

					if (xOffset > space - 2) {
						previewBox.style.left = `${xOffset - 2}%`;
					} else {
						previewBox.style.left = `${xOffset}%`;
					}
				}
			},

			setBubbleYOffset: value => {
				const chat = document.getElementById('chatPreview');
				chat.style.marginTop = `${value}%`;
			},

			calculateContainerHeight() {
				const container = document.getElementById('chat-bubble');
				const textDisplay = document.getElementById('preview-bubble-text');
				const lineHeight = parseInt(getComputedStyle(textDisplay).lineHeight);
				const fontSize = parseInt(getComputedStyle(textDisplay).fontSize);
				const numRows = 2;
				const height = numRows * lineHeight + 2 * 10; // 10px padding on top and bottom

				container.style.height = `${height}px`;
				container.scrollTop = textDisplay.offsetHeight;
			},

			async previewInBrowserSource() {
				isPreview.value = true;
				axios.post('/tts-chat-bubble/preview').finally(() => (isPreview.value = false));
			},

			async previewBubble() {
				const previewBox = document.getElementById('previewBox');
				previewBox.style.display = 'none';

				const text = 'This is a test message to preview your color theme settings.';

				const container = document.getElementById('chat-bubble');
				const textDisplay = document.getElementById('preview-bubble-text');

				dummyText.value = '';
				textDisplay.textContent = '';

				const textArray = text.split('');

				let index = 0;

				setTimeout(() => {
					previewBox.style.display = 'flex';
					actions.calculateContainerHeight();
					isPreview.value = true;
					typeText();
				}, 260);

				function typeText() {
					if (index < textArray.length) {
						textDisplay.textContent += textArray[index];
						index++;
						setTimeout(function () {
							typeText();
							container.scrollTop = textDisplay.offsetHeight;
						}, 125); // Adjust the typing speed here (milliseconds)
					} else {
						isPreview.value = false;
					}
				}

				// function calculateContainerHeight() {
				// 	const lineHeight = parseInt(getComputedStyle(textDisplay).lineHeight);
				// 	const fontSize = parseInt(getComputedStyle(textDisplay).fontSize);
				// 	const numRows = 2;
				// 	const height = numRows * lineHeight + 2 * 2;

				// 	container.style.height = `${height}px`;
				// }
			},
		};

		const icons = { mdiAlertCircleOutline };

		return {
			fontList,
			isExtensionOnline,
			isPreview,
			dummyText,
			data,
			actions,
			icons,
		};
	},
};
</script>

<style lang="scss">
:root {
	--bubble-triangle-color: #ffffff;
	--blinking-border-color: #000000;
}

.tts-chat-bubble {
	.color-box {
		width: 80px;
		height: 25px;
		border-radius: 4px;
		background: white;
		margin-left: 15px;
	}

	#chat-section {
		position: relative;
		height: 140px;
		width: 100%;
		overflow: hidden;
	}

	#chatPreview {
		position: absolute;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px;
		overflow: hidden;
	}

	.preview-box {
		position: absolute;
		// width: 100%;
		border-radius: 4px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 4px;

		// strong {
		//     text-align: center;
		//     font-size: 18px;
		//     font-family: 'Gotham Rounded', sans-serif !important;
		//     font-weight: bold !important;
		// }

		.bubble {
			position: relative;
			filter: drop-shadow(4.5px 5.468px 0px #ffffff);
			width: 75%;
			// width: 100%;
		}

		.user-profile {
			width: 75%;
			// width: 100%;

			.profile-box {
				background: white;
				box-shadow: 4.5px 5.468px 0px #ffffff;
				border-radius: 8px;
				padding: 4px 8px 4px;
				display: inline-block;
				height: 30px;
				margin-bottom: 14px;
				position: relative;

				.user-avatar {
					position: absolute;
					filter: none !important;
					width: 50px;
					height: 50px;
					top: -10px;
					left: -2px;
					border-radius: 50%;
					border: 3px solid;
					border-color: white;
					background: url('../../../assets/icons/bubble-icon.png');
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
				}

				.username {
					margin-left: 46px;
					color: black;
				}
			}

			// filter: none !important;
		}

		.chat-bubble {
			position: relative;
			display: inline-block;
			background-color: #ffffff;
			color: #000000ff;
			border-radius: 10px;
			height: 50px;
			width: 100%;
			padding: 5px 8px;
			max-height: 150px;
			overflow: hidden;
		}

		.bubble::after {
			content: '';
			position: absolute;
			bottom: -7px;
			left: 25px;
			transform: translateX(-50%);
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: var(--bubble-triangle-color) transparent transparent transparent;
			/* Additional properties for the tip */
			width: 0;
			height: 0;
		}

		#preview-bubble-text {
			overflow: hidden;
			width: 0;
			border: none;
			border-right: 0.15em solid black;
			animation: typing 5s steps(40, end) forwards, blink 0.7s infinite;
		}

		@keyframes typing {
			from {
				width: 0;
			}

			to {
				width: 100%;
			}
		}

		@keyframes blink {
			from {
				border-color: transparent;
			}

			to {
				border-color: var(--blinking-border-color);
			}
		}
	}

	.fade-in-up {
		position: relative;
		opacity: 0;
		/* Initially hide the div */
		animation: fade-in-up-animation 2s ease-in-out forwards;
		/* Apply the animation */
	}

	@keyframes fade-in-up-animation {
		from {
			opacity: 0;
			/* Start with opacity 0 */
			transform: translateY(20px);
			/* Start 20px below */
		}

		to {
			opacity: 1;
			/* Fade in to opacity 1 */
			transform: translateY(0);
			/* End at original position */
		}
	}
}
</style>
