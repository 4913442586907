<template>
	<div class="chat-alert">
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">Auto-Enable Settings</h3>
					<div class="d-flex justify-space-between align-end mb-2">
						<strong class="fs--14 fw--400">Auto-Enable New Global Stickers: </strong>
						<v-switch v-model="config.newGlobalStickerEnabled" color="pinkItem" hide-details></v-switch>
					</div>
					<div class="d-flex justify-space-between align-end mb-2">
						<strong class="fs--14 fw--400">Auto-Enable New Global TTS Stickers: </strong>
						<v-switch v-model="config.newGlobalTtsStickerEnabled" color="pinkItem" hide-details></v-switch>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mt-3">
						Use these options to automatically enable or disable any new Global Stickers or Global TTS
						Stickers we add to the extension.
					</p>
					<p class="fieldFore--text fs--12 fw--400">
						For example, if you prefer to manually approve and curate all new Global Stickers and Global TTS
						Stickers, disable these options. When disabled, all new Global Stickers and Global TTS Stickers
						will show in your dashboard as "Disabled", and you will need to manually Enable them for your
						viewers to use them.
					</p>
					<p class="fieldFore--text fs--12 fw--400">
						Please note we add new stickers regularly to the extension in order to help keep your content
						fresh. To help boost performance over time,
						<strong>we recommend keeping both toggles enabled ON.</strong>
					</p>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup() {
		return {};
	},
};
</script>
