<template>
	<div class="customize-theme">
		<v-card>
			<v-card-text>
				<section class="mb-3">
					<h3 class="fs--22 fw--400">Customize Theme</h3>
					<p class="fieldFore--text fs--12 fw--400">
						Select a color theme to match your brand's look and feel
					</p>

					<v-select
						v-model="config.panelTheme"
						:items="themes"
						item-text="name"
						item-value="value"
						solo
						hide-details
						flat
						background-color="purple"
						color="textColor">
					</v-select>
				</section>

				<img
					v-if="config.panelTheme"
					width="100%"
					:src="require(`@/assets/images/previews/${config.panelTheme}.png`)"
					alt="Theme Preview" />
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const themes = [
			{ name: 'Violet', value: 'violet' },
			{ name: 'Blue', value: 'blue' },
			{ name: 'Green', value: 'green' },
			{ name: 'Orange', value: 'orange' },
			{ name: 'Pink', value: 'pink' },
			{ name: 'Purple', value: 'purple' },
			{ name: 'Red', value: 'red' },
			{ name: 'Turquoise', value: 'turquoise' },
			{ name: 'Yellow', value: 'yellow' },
			{ name: 'Grey', value: 'grey' },
		];
		return {
			themes,
			icons: {
				mdiAlertCircleOutline,
			},
		};
	},
};
</script>
