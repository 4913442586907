<template>
	<div>
		<page-title title="Settings"></page-title>

		<v-tabs v-model="tab" slider-color="pink" color="white" background-color="transparent" grow>
			<v-tab>General</v-tab>
			<v-tab>Alerts</v-tab>
			<v-tab>Setup</v-tab>
			<v-tab>TTS</v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab">
			<v-tab-item>
				<v-row v-if="!isLoading && config">
					<v-col cols="12" md="6" class="mt-5">
						<General
							:key="generalKey"
							:config="config"
							@onRangeDataStart="actions.onRangeDataStart"
							@onRangeDataEnd="actions.onRangeDataEnd">
						</General>
						<NewGlobalStickers class="mt-5" :config="config" />
					</v-col>
					<v-col cols="12" md="6" class="mt-0 mt-md-5">
						<StickerPlacement :config="config"></StickerPlacement>
						<CustomizeTheme
							v-if="true"
							class="mt-5"
							:config="config"
							@onRangeDataStart="actions.onRangeDataStart"
							@onRangeDataEnd="actions.onRangeDataEnd" />
					</v-col>
				</v-row>

				<LoadingView v-if="isLoading" text="General Settings" />
			</v-tab-item>
			<v-tab-item>
				<v-row v-if="!isLoading && config">
					<v-col cols="12" md="6" class="mt-5">
						<OverlayAlert
							:config="config"
							:overlayColorData="overlayColorData"
							@onRangeDataStart="actions.onRangeDataStart"
							@onRangeDataEnd="actions.onRangeDataEnd" />
					</v-col>
					<v-col cols="12" md="6" class="mt-0 mt-md-5">
						<ChatAlert
							:config="config"
							@onRangeDataStart="actions.onRangeDataStart"
							@onRangeDataEnd="actions.onRangeDataEnd"></ChatAlert>
					</v-col>
				</v-row>

				<LoadingView v-if="isLoading" text="Alert Settings" />
			</v-tab-item>
			<v-tab-item>
				<v-row v-if="!isLoading && config">
					<v-col cols="12" class="mt-5">
						<BrowserMediaSource :config="config"></BrowserMediaSource>
					</v-col>
					<v-col cols="12" class="mt-0 mt-md-1">
						<TestSetup></TestSetup>
					</v-col>
				</v-row>

				<LoadingView v-if="isLoading" text="Setup Settings" />
			</v-tab-item>
			<v-tab-item>
				<v-row v-if="!isLoading && config && config.ttsAttrs">
					<v-col cols="12" md="6" class="mt-5">
						<TextToSpeech :config="config" />
						<TTSChatAlerts class="mt-5" :config="config" />
					</v-col>
					<v-col cols="12" md="6" class="mt-5">
						<TTSWordBlock :config="config" />
						<TTSChatBubble
							class="mt-5"
							:config="config"
							@onRangeDataStart="actions.onRangeDataStart"
							@onRangeDataEnd="actions.onRangeDataEnd" />
					</v-col>
				</v-row>

				<LoadingView v-if="isLoading" text="TTS Settings" />
			</v-tab-item>
		</v-tabs-items>

		<!-- <v-row class="mt-2">
			<v-col cols="12" md="6" class="mt-3">
				<General :key="generalKey" :config="config" @onRangeDataStart="actions.onRangeDataStart"
					@onRangeDataEnd="actions.onRangeDataEnd">
				</General>
				<NewGlobalStickers class="mt-5 mb-5" :config="config" />
				<ChatAlert
					:config="config"
					@onRangeDataStart="actions.onRangeDataStart"
					@onRangeDataEnd="actions.onRangeDataEnd"
					class="mt-5"></ChatAlert>
				<CustomizeTheme
					:config="config"
					@onRangeDataStart="actions.onRangeDataStart"
					@onRangeDataEnd="actions.onRangeDataEnd"
					class="mt-5" />
			</v-col>
			<v-col cols="12" md="6" class="mt-3">
				<TestSetup></TestSetup>
				<StickerPlacement :config="config" class="mt-5 mb-5"></StickerPlacement>
				<OverlayAlert :config="config" :overlayColorData="overlayColorData"
					@onRangeDataStart="actions.onRangeDataStart" @onRangeDataEnd="actions.onRangeDataEnd">
				</OverlayAlert>
			</v-col>
		</v-row> -->
	</div>
</template>
<script>
import axios from '@axios';
import { onBeforeUnmount, onMounted, reactive, ref } from '@vue/composition-api';
import router from '@/router';
import { streamerConfig } from '../../utils/sticker';
import { FormChangeWatcher } from '../../utils/form';
import PageTitle from '@/components/PageTitle.vue';
import General from './settings/General.vue';
import BrowserMediaSource from './settings/BrowserMediaSource.vue';
import TestSetup from './settings/TestSetup.vue';
import ChatAlert from './settings/ChatAlert.vue';
import CustomizeTheme from './settings/CustomizeTheme.vue';
import StickerPlacement from './settings/StickerPlacement.vue';
import NewGlobalStickers from './settings/NewGlobalStickers.vue';
import OverlayAlert from './settings/OverlayAlert.vue';
import TextToSpeech from './settings/TextToSpeech.vue';
import TTSWordBlock from './settings/TTSWordBlock.vue';
import TTSChatAlerts from './settings/TTSChatAlerts.vue';

import TTSChatTest from './settings/TTSChatTest.vue';
import TTSChatBubble from './settings/TTSChatBubble.vue';

import LoadingView from '@/components/LoadingView.vue';

// TODO Settings are being saved on load.  Need to figure out where.

export default {
	components: {
		PageTitle,
		General,
		BrowserMediaSource,
		TestSetup,
		ChatAlert,
		CustomizeTheme,
		StickerPlacement,
		OverlayAlert,
		NewGlobalStickers,
		TextToSpeech,
		TTSWordBlock,
		TTSChatAlerts,
		TTSChatBubble,
		TTSChatTest,
		LoadingView
	},
	setup() {
		const tab = ref(0);
		const config = reactive(streamerConfig);
		const isLoading = ref(false);

		onMounted(() => {
			if (router.currentRoute.query && router.currentRoute.query.tab) {
				const tabItem = router.currentRoute.query.tab;

				if (tabItem === 'general') {
					tab.value = 0;
				}
				if (tabItem === 'alerts') {
					tab.value = 1;
				}
				if (tabItem === 'setup') {
					tab.value = 2;
				}
				if (tabItem === 'tts') {
					tab.value = 3;
				}
			}
		});

		// Create watcher for data changes.
		const formChangeWatcher = new FormChangeWatcher();
		const ttsFormChangeWatcher = new FormChangeWatcher();
		onBeforeUnmount(() => {
			formChangeWatcher.stopWatching();
			ttsFormChangeWatcher.stopWatching();
			overlayColorDataWatcher.stopWatching();

			// Ensure that color changes are saved.
			if (overlayColorTimer) {
				clearTimeout(overlayColorTimer);
			}

			if (overlayColorChanged) {
				Object.assign(config, overlayColorData);
				actions.saveChanges();
			}
		});

		// Create custom model and watcher for the color picker
		// in OverlayAlert. The picker does not provide a start /
		// end event like the slider so it sends data changes too
		// quickly for us to send to the server.
		const overlayColorDataWatcher = new FormChangeWatcher();
		const overlayColorData = reactive({
			overlayColor: null,
			overlayOutline: null,
		});
		let overlayColorChanged = false;
		let overlayColorTimer = null;

		// Schedule overlay color changes to be saved in the future
		// so we don't spam the server with save requests.
		const scheduleOverlayColorChanges = () => {
			if (overlayColorTimer) {
				return;
			}
			overlayColorTimer = setTimeout(saveOverlayColorChanges, 3000);
		};

		// Saves overlay color changes.
		const saveOverlayColorChanges = () => {
			if (overlayColorChanged) {
				overlayColorChanged = false;
				overlayColorTimer = null;
				Object.assign(config, overlayColorData);
			}
		};

		// For some reason, the new sticker default bits dropdown is not recognizing
		// the config value once it's loaded from the server. So we force a reload.
		const generalKey = ref(new Date().getTime());
		let isSaving = false;

		const actions = {
			loadData: () => {
				isLoading.value = true;

				formChangeWatcher.stopWatching();
				ttsFormChangeWatcher.stopWatching();
				overlayColorDataWatcher.stopWatching();

				axios.get('/config').then(res => {
					// Copy data over.
					Object.assign(config, res.data);
					generalKey.value = new Date().getTime();
					formChangeWatcher.startWatching(config, actions.saveChanges);
					ttsFormChangeWatcher.startWatching(reactive(config.ttsAttrs), actions.saveChanges);

					// Update custom model for color picker.
					overlayColorData.overlayColor = config.overlayColor;
					overlayColorData.overlayOutline = config.overlayOutline;
					overlayColorDataWatcher.startWatching(overlayColorData, () => {
						overlayColorChanged = true;
						scheduleOverlayColorChanges();
					});
				}).finally(() => {
					isLoading.value = false
				});
			},
			saveChanges: () => {
				if (!isSaving) {
					isSaving = true;
					axios
						.put('/config', config)
						.catch(error => console.log(error))
						.finally(() => (isSaving = false));
				}
			},
			onRangeDataStart: () => {
				formChangeWatcher.onRangeDataStart();
				ttsFormChangeWatcher.onRangeDataStart();
			},
			onRangeDataEnd: () => {
				formChangeWatcher.onRangeDataEnd();
				ttsFormChangeWatcher.onRangeDataEnd();
			},
		};

		actions.loadData();

		return {
			isLoading,
			tab,
			actions,
			config,
			overlayColorData,
			generalKey,
		};
	},
};
</script>
