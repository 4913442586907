<template>
	<div class="chat-alert">
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">Chat Alert</h3>
					<div class="d-flex justify-space-between align-end mb-2">
						<strong class="fs--14 fw--400"> Enable: </strong>
						<v-switch v-model="config.chatEnabled" color="pinkItem" hide-details></v-switch>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-0">
						Displays a text alert message in the chat room when a viewer slaps a sticker on the live stream.
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<section class="pb-4">
					<h3 class="fs--14 fw--400 mt-3">Message:</h3>
					<v-textarea
						v-model="config.chatText"
						@keydown="$emit('onRangeDataStart')"
						@focusout="$emit('onRangeDataEnd')"
						class="mt-2"
						flat
						background-color="purple"
						color="textColor"
						rows="2"
						no-resize
						solo
						hide-details></v-textarea>

					<p class="fieldFore--text fs--12 fw--400 mt-2">
						Customize the alert message to be posted in the chat room. You can use the following tags:
						<span class="pink--text">#user</span> = viewer name, <span class="pink--text">#sticker</span> =
						sticker name, <span class="pink--text">#bits</span> = amount of Bits used
					</p>

					<p class="pink--text fs--14 fw--600 mb-0">
						<v-icon class="add-slot" color="pink">{{ icons.mdiAlertCircleOutline }}</v-icon>
						Please be sure to give StreamStickers moderator status to prevent messages from being blocked
						due to spam or follower-only mode filters, etc.
					</p>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup() {
		return {
			icons: {
				mdiAlertCircleOutline,
			},
		};
	},
};
</script>
