<template>
    <div class="tts-chat">
        <v-card>
            <v-card-text>
                <div class="bubble">
                    <div class="chat-bubble container" id="container">
                        <span class="" id="text"></span>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
export default {
    setup() {
        const text = ref('');
        const duration = ref(0);

        onMounted(() => {
            actions.playAudio();
        })

        const actions = {
            async playAudio() {
                const file = require('@/assets/tts/speech pause.mp3');
                const audioElement = document.createElement('audio');
                audioElement.src = file;

                const text = "This is a test ah pauses within the speech     we'll be checking here to see if pauses work properly This is a test ah pauses within the speech     we'll be checking here to see if pauses work properly";

                const container = document.getElementById('container');
                const textDisplay = document.getElementById('text');

                const textArray = text.split('');

                let typingTimeout;
                let index = 0;

                function typeText() {
                    if (index < textArray.length) {
                        textDisplay.textContent += textArray[index];
                        index++;
                        setTimeout(function () {
                            calculateContainerHeight();
                            typeText();
                            container.scrollTop = textDisplay.offsetHeight;
                            // if (textDisplay.scrollHeight > container.clientHeight) {
                            //     container.scrollTop = textDisplay.offsetHeight - container.offsetHeight;
                            // }
                        }, 125); // Adjust the typing speed here (milliseconds)

                    }
                }

                function calculateContainerHeight() {
                    const lineHeight = parseInt(getComputedStyle(textDisplay).lineHeight);
                    const fontSize = parseInt(getComputedStyle(textDisplay).fontSize);
                    const numRows = 2;
                    const height = numRows * lineHeight + (2 * 10); // 10px padding on top and bottom

                    container.style.height = `${height}px`;
                }

                audioElement.addEventListener('canplaythrough', () => {
                    const audioDuration = audioElement.duration * 1000; // Convert duration to milliseconds
                    typingTimeout = audioDuration / textArray.length;
                    audioElement.play();
                    setTimeout(() => {
                        calculateContainerHeight();
                        typeText();
                    }, 200)
                })

                audioElement.addEventListener('ended', () => {
                    clearTimeout(150);
                });
            }
        }
    }
}
</script>

<style scoped>
.bubble {
    position: relative;
    filter: drop-shadow(4.5px 6.468px 0px #ba00ff);
}
.chat-bubble {
    position: relative;
    display: inline-block;
    background-color: #e1f7e3;
    color: #333;
    border-radius: 10px;
    height: 50px;
    width: 300px;
    padding: 10px;
    max-height: 150px;
    overflow: hidden;
}


.container {
    display: inline-block;
}


.bubble::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 25px;
    z-index: 5;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #e1f7e3 transparent transparent transparent;
    width: 0;
    height: 0;
}

#text {
    overflow: hidden;
    width: 0;
    border-right: .15em solid black;
    animation:
        typing 5s steps(40, end) forwards,
        blink .7s infinite;
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes blink {
    from {
        border-color: transparent
    }

    to {
        border-color: black;
    }
}
</style>