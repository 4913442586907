<template>
	<div class="tts-copy">
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">TTS Chat Alerts</h3>
					<h3 class="fs--16 fw--400 mt-3">TTS Sticker (Sticker Mode)</h3>
					<div class="d-flex align-end mb-2">
						<v-switch v-model="config.ttsAttrs.chatEnabled" color="pinkItem" hide-details></v-switch>
						<strong class="fs--14 fw--400 pl-4">Enable </strong>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-3">
						This feature displays a customized text message on the live stream when a viewer slaps a TTS
						Sticker.
					</p>

					<h3 class="fs--14 fw--400 mt-3">Message:</h3>
					<v-textarea
						v-model="config.ttsAttrs.chatText"
						class="mt-2"
						flat
						background-color="purple"
						color="textColor"
						rows="2"
						no-resize
						solo
						hide-details></v-textarea>

					<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
						Customize the alert message to be posted in the chat room. You can use the following tags:
						<span class="pink--text">@user</span> = viewer name, <span class="pink--text">#sticker</span> =
						sticker name, <span class="pink--text">#bits</span> = amount of Bits used,
						<span class="pink--text">#message</span> = the message sent.
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<section class="pb-4">
					<h3 class="fs--16 fw--400 mt-3">TTS Sticker (Co-Host Mode)</h3>
					<div class="d-flex align-end mb-2">
						<v-switch v-model="config.ttsAttrs.coHostChatEnabled" color="pinkItem" hide-details></v-switch>
						<strong class="fs--14 fw--400 pl-4">Enable </strong>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-3">
						This feature displays a customized text message on the live stream when a viewer slaps a TTS
						Co-Host Sticker.
					</p>

					<h3 class="fs--14 fw--400 mt-3">Message:</h3>
					<v-textarea
						v-model="config.ttsAttrs.coHostChatText"
						class="mt-2"
						flat
						background-color="purple"
						color="textColor"
						rows="2"
						no-resize
						solo
						hide-details></v-textarea>

					<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
						Customize the alert message to be posted in the chat room. You can use the following tags:
						<span class="pink--text">#user</span> = viewer name, <span class="pink--text">@sticker</span> =
						sticker name, <span class="pink--text">#bits</span> = amount of Bits used,
						<span class="pink--text">#message</span> = the message sent.
					</p>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return {
			icons: {
				mdiAlertCircleOutline,
			},
		};
	},
};
</script>
