<template>
	<div>
		<v-card>
			<v-card-text>
				<h3 class="fs--22 fw--400">Sticker Placement</h3>

				<div class="placement-button-card pa-4 mt-3">
					<v-btn-toggle v-model="placements" active-class="secondary" multiple mandatory>
						<v-row>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(0)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">TOP LEFT</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(1)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">TOP</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(2)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">TOP RIGHT</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(3)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">LEFT</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(4)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">CENTER</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(5)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">RIGHT</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(6)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">BOTTOM LEFT</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(7)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">BOTTOM</div>
									</div>
								</v-btn>
							</v-col>
							<v-col cols="6" sm="4" class="card-btn">
								<v-btn class="multi-button-inactive" block>
									<div class="d-sm-flex align-center">
										<v-icon v-if="doShowCheck(8)" size="20">{{ icons.mdiCheckCircle }}</v-icon>
										<div class="fs--12 mt-2 mt-sm-0">BOTTOM RIGHT</div>
									</div>
								</v-btn>
							</v-col>
						</v-row>
					</v-btn-toggle>
				</div>

				<p class="fieldFore--text fs--12 fw--400 mt-3">
					Use the grid above to restrict sticker placement. Stickers will only be placed in the highlighted
					areas. Please note these settings will be <strong>IGNORED</strong> for stickers that have
					<strong>CUSTOM PLACEMENT</strong> activated.
				</p>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { mdiCheckCircle } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	setup(props, ctx) {
		// Map model and UI placements.
		const placements = computed({
			get() {
				const spp = props.config.stickerPlacementPostion;
				const values = [];
				if (spp && spp.length > 0) {
					for (let i = 0; i < 9; i++) {
						if (spp[i]) {
							values.push(i);
						}
					}
				}
				return values;
			},
			set(newValue) {
				const values = [];
				for (let i = 0; i < 9; i++) {
					values[i] = newValue.includes(i);
				}
				props.config.stickerPlacementPostion = values;
			},
		});

		const doShowCheck = function (index) {
			return placements.value.includes(index);
		};

		const icons = {
			mdiCheckCircle,
		};

		return {
			placements,
			doShowCheck,
			icons,
		};
	},
};
</script>

<style lang="scss" scoped>
$site-back-color: map-deep-get($material-dark, 'background');
$card-back-color: map-deep-get($material-dark, 'cards');
$active-button-color: var(--v-primary-base);

.placement-button-card {
	border-style: solid;
	border-radius: 6px;
	border-width: 2px;
	background-color: var(--v-purple-base);
	border-color: var(--v-primary-base);

	.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
		background: transparent !important;
	}

	.card-btn {
		background-color: $site-back-color;
		padding: 3px;

		button {
			min-height: 100px !important;
		}

		.v-btn {
			min-width: 100% !important;
			max-width: 100% !important;
		}

		.v-icon {
			margin-right: 5px;
		}
	}
}
</style>
